import { Component, Vue, Ref } from "vue-property-decorator";
import { AxiosError } from "axios";
import { checkEmail, isEmpty } from "@helpers";
import { ApiError, ApiErrorResponse } from "@/types/api";
import { setUserProfileInfo } from "@/lib/auth";
import { AuthActions } from "@store/modules/auth/types";
import { ProfileActions, ProfileState } from "@store/modules/profile/types";
import { authModule, profileModule } from "@store/namespaces";
import { VForm } from "@/types/vuetify";

@Component
export default class Login extends Vue {
  @Ref("form") private readonly formRef!: VForm;
  @profileModule.State("status")
  private readonly profileStatus!: ProfileState["status"];
  @authModule.Action("logIn") private logInAction!: AuthActions["logIn"];
  @profileModule.Action("fetchProfile")
  private readonly fetchProfileAction!: ProfileActions["fetchProfile"];

  private showedPassword = false;
  private email = "";
  private password = "";
  private loading = false;
  private fa2Code = "";
  private requiredFa2Code = false;

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      email: (v: string) =>
        checkEmail(v) ||
        this.$vuetify.lang.t("$vuetify.errors.email.incorrect"),
    };
  }

  private toggleShowPassword() {
    this.showedPassword = !this.showedPassword;
  }

  private resetFa2Code() {
    this.requiredFa2Code = false;
    this.fa2Code = "";
  }

  private async logIn() {
    if (this.loading || !this.formRef.validate()) return;

    this.loading = true;

    try {
      const email = this.email.trim();

      await this.logInAction({
        email,
        password: this.password,
        secondFactorCode:
          this.requiredFa2Code && this.fa2Code ? this.fa2Code : undefined,
      });

      setUserProfileInfo({
        email: this.email,
      });

      await this.fetchProfileAction();
    } catch (err) {
      const error = err as AxiosError<ApiErrorResponse>;
      const errorCode = error?.response?.data?.error;

      if (errorCode === ApiError.ACCOUNT_EMAIL_NOT_VERIFIED) {
        this.$router.push({ name: "confirm-email" });
      } else if (this.profileStatus === "disabled") {
        this.$router.push({ name: "blocked-account" });
      } else if (this.profileStatus === "not_verified") {
        this.$router.push({ name: "verify" });
      } else if (errorCode === ApiError.SECOND_FACTOR_REQUIRED) {
        this.requiredFa2Code = true;
      }
    }

    this.loading = false;
  }

  private mounted() {
    this.$watch(
      () => {
        return this.$vuetify.lang.current;
      },
      () => {
        this.formRef.validate();
      }
    );
  }
}
